import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {isAuthenticated, login} from "../../utils/auth"

const PriorityDebtPage = ({debts, dispatch}) => {
  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  return (
    <Layout>
      <SEO title="Priority Debts" />
      <h2>Check you’ve paid your priority debts</h2>
      <p>
      Before we get going, double check you don’t have any priority debts.These aren’t always the most expensive debts but rather the ones with the biggest consequences for not paying them off. Use the following list to check if you’ve got any and if you do, pay them off as soon as possible.
      </p>
      <ul>
        <li>Mortgage payments or secured loan</li>
        <li>Rent</li>
        <li>Council tax</li>
        <li>Child maintenance</li>
        <li>Magistrates' court fines</li>
        <li>Tax, VAT or N.I.</li>
        <li>County court judgement</li>
        <li>TV licence</li>
        <li>Gas or electricity</li>
        <li>Hire purchase or logbook loan</li>
        <li>Telephone bill</li>
      </ul>
      <p className="text-danger">In emergency ask for help! </p>
      <p>If you’re struggling to even pay off your priority debts each month, don’t panic – there are things you can do:</p>
      <ul>
        <li>Talk to the lender immediately to see if you can lower your monthly payments</li>
        <li>Make sure you’re getting the best possible deal on your utilities</li>
        <li>Speak to your local council to check you’re in the correct council tax band</li>
      </ul>
      <p>If you’re facing a sudden debt emergency, such as court action, bailiffs or repossession,
get free independent help immediately. A non-profit debt counsellor will be able to talk
to the court, bailiff or creditor on your behalf.</p>

      <div className="text-center mt-5 mb-5">
          <Link to="/user/debt" className="btn btn-primary">Continue</Link>
      </div>

    </Layout>
  )
}

export default PriorityDebtPage
